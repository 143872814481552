import React, { Component } from 'react';

import { Link } from "react-router-dom";

import imgrcslt from './img/rcslt_logo.png';
import imgasltip from './img/asltip_logo.png';
import imghcpc from './img/hcpc_logo.png';

import CONSTANTS from './content/constants'


export default class Footer extends Component {
  render() {
    return (

      <div class="footer_content">
      	<div class="footer_extras">
         <div class="footer_contact footer_extra">
          <p>Contact</p>
      			Call {CONSTANTS.phone}<br/><a href={"mailto:"+ CONSTANTS.email}>Email me</a><br/>
            <a target="_blank" rel="noreferrer" href="http://uk.linkedin.com/pub/alison-greig/49/121/839"><img src="http://www.linkedin.com/img/webpromo/btn_in_20x15.png" width="20" height="15" alt="View Alison Greig's LinkedIn profile" border="0" /></a>
          </div>

       		<div class="footer_site_map footer_extra">
      			<p>Site Map</p>
            <Link to="/">Home</Link><br />
            <Link to="/about">About Me</Link><br />
            <Link to="/who">Who Can I Help?</Link><br />
            <Link to="/testimonial">Testimonials</Link><br />
            <Link to="/fees">Fee Structure</Link><br />
            <Link to="/ideas">Ideas</Link><br />
            <Link to="/terms">Terms and Conditions</Link><br />
            <Link to="/privacy">Privacy Notice</Link><br />
          </div>
  		    </div>
      		<div class="footer_logos">
            <a  target="_blank" rel="noreferrer" href="http://www.rcslt.org/"><img border="0" src={imgrcslt} width="65" height="75" alt="RCSLT Member Logo" /></a>
            <a  target="_blank" rel="noreferrer" href="http://www.helpwithtalking.com/"><img src={imgasltip} width="79" height="75" alt="ASLTIP Logo" /></a>
            <a  target="_blank" rel="noreferrer" href="http://www.hcpc-uk.org/"><img src={imghcpc} width="88" height="75" alt="HCPC Logo" /></a>
       		<div class="footer_extra">
  			  </div>
       	</div> {/* footer_extras */}

    	  <div class="footer_copy">
      		Copyright © {new Date().getFullYear()} <a href="http://www.ag-therapy.com/">Alison Greig Speech And Language Therapy</a>.<br/>
      		Website by <a href="http://www.simongreig.com">Simon Greig</a>.
      	</div>


  	</div>

  );
  }
}
