import React, { Component } from 'react'
import imgpintrest from './idea_images/pinterest.png'
import imgfliptowin from './idea_images/fliptowin.jpg'
import imgcupcakes from './idea_images/cupcakes.png'


export default class Ideas extends Component {
  render() {
    return (
      <div class="main_copy fltlft">

      <h1>Speech and Language Therapy Ideas</h1>
      <ul class="ideas_list">
        <li><a href='#Activities_for_Improving_Listening'>Activities for Improving Listening</a></li>
        <li><a href='#Cooking_with_Kids'>Cooking with Kids</a></li>
        <li><a href='#Copy_Cat!'>Copy Cat!</a></li>
        <li><a href='#It’s_Time_to_Rhyme!'>It’s Time to Rhyme!</a></li>
        <li><a href='#Parent_Recommendation-_Flip_to_Win_Memory_Game'>Parent Recommendation- Flip to Win Memory Game</a></li>
        <li><a href='#Really_Pinteresting!'>Really Pinteresting!</a></li>
        <li><a href='#Specific_Language_Impairment_You_Tube_Channel'>Specific Language Impairment You Tube Channel</a></li>
        <li><a href='#Speech_and_Language_Milestones'>Speech and Language Milestones</a></li>
        <li><a href='#Speech_with_Milo_-_verbs_(iPad_App)'>Speech with Milo - verbs (iPad App)</a></li>
        <li><a href='#Summer_Holiday_Speech_and_Language_Activities'>Summer Holiday Speech and Language Activities</a></li>
        <li><a href='#Summer_Holiday_Speech_and_Language_Activities_for_Older_Children'>Summer Holiday Speech and Language Activities for Older Children</a></li>
      </ul>


      <div class='idea_all'><div id='Activities_for_Improving_Listening' class='idea_title'><h2>Activities for Improving Listening</h2></div><div class='idea_content'><p>Many parents feel concerned that their child doesn’t listen very well.  Here are some simple ideas that can help.  These are suitable for 4 to 7 years but can be adapted for younger or older children.</p>
    <p>I’ve used ‘he’ to refer to the child but as you would expect girls can also experience problems with attention listening and will benefit equally from the ideas below.</p>
    <p><strong>General points</strong></p>
    <ul>
    <li>Always focus your child’s attention before giving instructions e.g. ‘Tom…listen..’</li>
    <li>Refocus his attention where necessary</li>
    <li>Give positive feedback e.g. ‘That was very good listening’</li>
    <li>Reduce background noises and distractions where possible</li>
    <li>Tell your child why he needs to listen e.g. ‘You need to listen so you know what to do next’</li>
    </ul>
    <p><strong>Activities</strong></p>
    <ul>
    <li>Read a short story or part of a story to your child and then ask him some questions about it or get him to re-tell the story (making sure he is sitting still and ready to listen.  Don’t show him the pictures)</li>
    <li>Play games such as Simon Says e.g. Simon says touch your nose, toes and hair, ‘Simon says clap your hands before you turn around’</li>
    <li>Have a pile of dressing up clothes.  Give your child a list of 3 things to put on.  Encourage him to find all the clothes first before putting them on so he doesn’t forget</li>
    <li>Give your child a message to pass on to someone in another room.  You can make these sensible or silly e.g. ‘Tell Dad that he needs to wash the car tomorrow/ Tell Mum that I want to have a worm sandwich for my lunch’</li>
    <li>If he copes well you could try ones where he has to listen to and remember a response e.g. ‘Go and ask Dad what time he wants to go to the supermarket’</li>
    <li>Play Chinese Whispers</li>
    <li>Ask your child to be silent for one minute and then tell you what he has heard e.g. cars, lawnmower, shouting, music playing upstairs, kettle boiling (it may be a good idea to use a clock or sand timer so he knows how long he needs to sit still for)</li>
    <li>Collect some objects that make a noise and show them to your child.  He has to turn his back while you make a noise and then guess what it was e.g. whistle, cup and spoon, bunch of keys, box of cereal, tin containing rice, paperback book</li>
    <li>Play the <em>Beans</em> game.  Different actions represent different types of ‘beans’.  Call out a bean name and your child (along with anyone else who is playing) has to do the action:
    <ul>
    <li>Runner bean = run on the spot</li>
    <li>Baked bean = fan yourself as it’s very hot</li>
    <li>Broad bean = make yourself as big as you can</li>
    <li>French bean = say ‘ooh la la!’</li>
    <li>Jelly bean = wobble</li>
    <li>Give your child a series of instructions to draw. E.g.
    <ol>
    <li>Draw a big square in the middle of the paper</li>
    <li>Draw 3 windows and a blue door</li>
    <li>Draw 2 trees in the garden</li>
    <li>Draw 4 tall flowers</li>
    </ol></li>
    </ul></li>
    </ul>
    <p>Remember to make the activities fun so that your child is motivated to join in.  He definitely won’t pay attention and listen if he’s bored!</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Cooking_with_Kids' class='idea_title'><h2>Cooking with Kids</h2></div><div class='idea_content'><p><img src={imgcupcakes} width="600"/>An advert I saw on television recently for a cooking magazine aimed at pre-school age children has inspired me to write about how fantastic cooking activities are for your child’s language development.  It’s fun, easy, relatively cheap, and best of all there’s something to eat at the end of it!
    There is so much that can be learned from spending time together in the kitchen:</p>
    <ul>
    <li>Concentration</li>
    <li>Delayed gratification which helps with impulsivity and self-control e.g. waiting for something to cook/cool</li>
    <li>Following instructions</li>
    <li>Vocabulary including object names (e.g. bowl, scales, items of food), actions (e.g. stir, pour, peel, cut, wait), describing words (e.g. hot, runny, sticky, wet, dry)</li>
    <li>Sequencing skills e.g. ‘First we… and then…’</li>
    <li>Reasoning skills e.g. why do we… wear an apron/measure the ingredients or why has the cake burned?!</li>
    <li>Taking turns and cooperation e.g. siblings could take turns to stir the mixture</li>
    <li>Social skills e.g. thinking about what other people would like to eat</li>
    </ul>
    <p>Make sure the food you choose to make is simple and quick so that your child can be involved at every stage.  You don’t always have to cook as there are plenty of recipes that just involve putting a few ingredients together – such as the very simple banana sundae on <a href="http://www.bakingmad.com/">www.bakingmad.com</a>.  There are lots of other websites and books with recipes for children available.</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Copy_Cat!' class='idea_title'><h2>Copy Cat!</h2></div><div class='idea_content'><p>Children learn many skills through imitation and this includes how to communicate. Even at a very young age most children will copy the things they see happening around them.  However some children do not imitate as readily as others and need encouragement to copy what they see and hear.</p>
    <p>If you are concerned that your child has a delay in speech, language or social interaction and you have noticed that he/she doesn’t seem to copy very much it is a really good idea to work on imitation skills.  The ability to copy the very complex movements required to produce speech can be challenging so it is best to start with much easier activities and then gradually build up to sounds (ideally with the guidance of a speech and language therapist).</p>
    <p>Many everyday tasks provide an opportunity for imitation such as putting toys away, sweeping the floor, folding the washing or setting the table.</p>
    <p>Try physical activities involving large body movements e.g. jumping, hopping, skipping, crawling, waving, placing hands on head etc    You could combine this with songs/games such as ‘Simon says…’, ‘Heads Shoulders Knees and Toes’, ‘Follow the Leader’ or the ‘Hokey Cokey’.</p>
    <p>Try activities that involve noises.  If you have instruments at home these can work well (e.g. keyboard, drum, tambourine) but if you don’t have any instruments banging on the table/tins/boxes or clapping hands can work equally well.</p>
    <p>A good way to encourage children to copy you is to start with copying what they do.  Children are more likely to participate in activities they have chosen themselves and it can encourage other skills such as eye contact and turn-taking.  Observe your child closely and start to imitate his/her actions or sounds e.g. if he/she jumps up and down, you jump up and down.  If he/she says ‘uh oh’ you say ‘uh oh’.  When playing with toys it can be a good idea to have two sets of toys or objects so that you don’t need to share.</p>
    <p>As always, it is essential that the activities are fun and varied so that your child is motivated to join in and can maintain his/her concentration.</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='It’s_Time_to_Rhyme!' class='idea_title'><h2>It’s Time to Rhyme!</h2></div><div class='idea_content'><p>My favourite nursery rhyme when I was a child was ‘There was an old woman who swallowed a fly’.  I knew all the words off by heart and loved to recite it whilst looking through an amazing book which had holes in it to show all the different things the woman had swallowed!  This book is a real classic with wonderful  illustrations and is <a href="http://www.amazon.co.uk/There-Swallowed-Classic-Books-Holes/dp/0859537277">still available today</a>.</p>
    <p>Nursery rhymes are a simple and fun way to support your child’s speech, language and literacy development from birth.   The rhyming and repetition can help to develop awareness of sounds which is crucial for the development of reading and writing.  The rhymes can also help with listening, vocabulary, memory and social interaction.</p>
    <p>Here is a list of both modern and more traditional rhymes to give you some ideas.  They can be sung or spoken and many have actions. If a rhyme doesn’t have any actions you can always invent your own!  The words can easily be <a href="http://www.rhymes.org.uk/">found on the internet</a> if you’ve forgotten them.</p>
    <ul>
    <li>Round and round the garden</li>
    <li>Baa baa black sheep</li>
    <li>Grand old duke of York</li>
    <li>Hey diddle diddle</li>
    <li>Hickory dickory dock</li>
    <li>Incey wincey spider</li>
    <li>Jack and Jill went up the hill</li>
    <li>Humpty Dumpty</li>
    <li>Pat a cake</li>
    <li>12345 once I caught a fish alive</li>
    <li>Ring a ring a roses</li>
    <li>Rain rain go away</li>
    <li>Little Miss Muffet</li>
    <li>Little Bo Beep</li>
    <li>Polly put the kettle on</li>
    <li>3 little kittens</li>
    <li>And don’t forget ….There was an old woman who swallowed a fly.</li>
    </ul>
    <p>One of the fantastic things about nursery rhymes is that you don’t need to spend any money.  The only thing you do need to spend is a few minutes of your time.</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Parent_Recommendation-_Flip_to_Win_Memory_Game' class='idea_title'><h2>Parent Recommendation- Flip to Win Memory Game</h2></div><div class='idea_content'><p><img src={imgfliptowin} width="600"/>Parents often ask me to recommend books, games and Apps they can buy to help their child’s speech and language development. There are many resources out there but they are often very general so it is important to use ones that are appropriate for your child’s individual need. Once parents are fully aware of the specific areas that their child needs help with they often find great resources themselves and end up recommending them to me! This was definitely the case with <a href="http://www.amazon.co.uk/Melissa-Doug-Flip-Memory-Game/dp/B0020V66NW/ref=pd_cp_k_h_b_cs_0">Melissa &amp; Doug Flip to Win Memory Game</a> (available from Amazon, Early Learning Centre and independent toy shops. Prices range from £8 to £10).</p>
    <p>I was working with a 4 year old boy who needed help with developing his language skills. His mum found this game whilst browsing in a local toy shop and I liked it so much that I went out and bought it straightaway! It has 7 themed game cards (fruit, colours, zoo, numbers, shapes, vehicles and farm) and is played just like a pairs game: the aim is to find two pictures the same. You open two little wooden doors and if the pictures match you get a point (which is recorded on the movable score tracker). It does have American words such as ‘garbage truck’ so you will need to change this to the English equivalent. Also the shapes card has ‘trapezoid’ and ‘pentafoil’ which are definitely not essential words for a young child to learn, in fact I’m not sure I’ve ever come across pentafoil myself! I tend not to dwell too much on these unless the child is particularly interested in learning them.</p>
    <p>The game can help children with a range of skills including:</p>
    <ul>
    <li>vocabulary development</li>
    <li>categorisation/grouping</li>
    <li>verbal reasoning (e.g. why things go together)</li>
    <li>turn-taking</li>
    <li>generalising speech sounds e.g. the ‘farm’ picture is very good for children who have been learning ‘k’ and ‘g’ (cat, cow, pig, goat, dog, duck, turkey, cockerel)</li>
    <li>visual memory</li>
    <li>attention</li>
    </ul>
    <p>As there are not many category cards the novelty wears off after a while but isn’t that the same with all games?! It is fun, durable, easily accessible, and reasonably well-priced so if your 3-4 year old needs help in any of the above areas it could be a worthwhile purchase.  It is suitable for all children, not just those with speech and language needs so can easily be passed on to siblings.</p>
    <p>If you have come across any books, games or Apps that you have found useful please let me know.</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Really_Pinteresting!' class='idea_title'><h2>Really Pinteresting!</h2></div><div class='idea_content'><p><img src={imgpintrest} width="600"/>As adults it’s important that we put ourselves in children’s shoes.  Imagine what it would be like if you can’t find the words to express your needs, or when you do speak people can’t understand you, or people around you are saying things you don’t understand.  It is completely understandable that children with speech and language problems can sometimes become frustrated or angry.</p>
    <p>I’m always looking for new strategies that parents can try at home to help children cope with their feelings.  Social web site <a href="https://pinterest.com/">Pinterest</a> can be a great resource for both parents and professionals.  If you have not come across it, <a href="https://pinterest.com/">Pinterest</a> is a pin board style website that allows users to create or read theme-based collections such as events, interests or hobbies.  There is a <a href="http://pinterest.com/all/kids/">category called Kids</a> that has lots of ideas on all aspects of a child’s life: early development, food, behaviour, fun activities, education etc.</p>
    <p>One idea that I really like is the glitter jar that can be used with children for time out or as distractor to give them time to calm down when upset or angry.  It can also be used as a ‘mind jar’ to help children cope with anxiety or fears.  It’s very simple to make.  You put 1-2 tablespoons of glitter glue, 3-4 teaspoons of glitter, warm water, and a drop of food colouring in a screw top jar.  The child shakes the jar and watches the glitter fall.  When all the glitter has settled and the child has calmed down the time out is over.</p>
    <p>Here’s a <a href="http://gembelina.wordpress.com/2013/01/18/calming-glitter-jar/">link to one mum’s experience</a>.</p>
    <p>One word of caution – don’t give your child the jar if they are very upset and there is a risk they might throw it.  Plastic bottles or jars may be a better option for some.</p>
    <p>This is just one tool for managing behaviour and won’t work for every child but might just be worth a try if other strategies have failed or are no longer effective.</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Specific_Language_Impairment_You_Tube_Channel' class='idea_title'><h2>Specific Language Impairment You Tube Channel</h2></div><div class='idea_content'><p>I want to tell you about the <a href="https://www.youtube.com/user/RALLIcampaign">Raising Awareness of Language Learning Impairments (RALLI) You Tube Channel</a> that has recently been launched to help spread the word about Specific Language Impairment (SLI).</p>
    <p>Many people have never heard of SLI even though it is more common than Autism and about as common as Dyslexia.  So what is SLI?  A really clear and parent-friendly description can be found on the <a href="http://www.ican.org.uk/">I CAN website</a> ( I CAN Help is a range of information services that provide help and advice to parents and practitioners about speech, language and communication):</p>
    <p>‘Specific Language Impairment’ or SLI is a type of speech, language and communication need (SLCN).  Children with SLI are usually as able and healthy as other children in all ways, with one exception; they have enormous difficulty talking and understanding language. This is their <strong>main area of difficulty</strong>.</p>
    <ul>
    <li>Children with SLI are all very individual. They may:</li>
    <li>Have difficulty saying what they want to, even though they have ideas</li>
    <li>Talk in sentences but be difficult to understand</li>
    <li>Sound muddled; it can be difficult to follow what they are saying</li>
    <li>Find it difficult to understand words and long instructions</li>
    <li>Have difficulty remembering the words they want to say</li>
    <li>Find it hard to join in and follow what is going on in the playground
    SLI is a very broad category, with some children having mild problems that are short-lived. Others have severe and persistent difficulties with both understanding and talking.</li>
    </ul>
    <p>These difficulties are not associated with other conditions, such as cerebral palsy, hearing impairment or autistic spectrum disorders. Children with SLI are often as clever as any other child of their age but they still have difficulties with speech and language, hence the term ‘specific’, as difficulties are specific to this area.</p>
    <p>It can be difficult to remember that they are often as able as their classmates; children with SLI often find ways of getting by, such as watching and copying. Having SLI can be frustrating for children; they may develop behaviour difficulties because of this. Very often children with SLI have difficulty learning to read and spell.</p>
    <p>The aim of the RALLI channel is to achieve wider recognition of SLI so that children can be diagnosed early and appropriate support provided.  This is absolutely crucial as persisting language difficulties can have a significant and long-lasting impact on a child’s self-esteem and academic progress.</p>
    <p>Short videos about SLI from various perspectives are posted on the channel.  These include children talking about the impact of SLI on their life and summaries of recent research.</p>
    <p>If you are concerned that your child may have a Specific Language Impairment a detailed assessment should be carried out by a Speech and Language Therapist such as myself.  This can be carried out on the NHS or privately.</p>
    <p>For more information visit:</p>
    <ul>
    <li><a href="https://www.youtube.com/RALLIcampaign">www.youtube.com/rallicampaign</a></li>
    <li><a href="http://www.ican.org.uk/">www.ican.org.uk</a></li>
    </ul></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Speech_and_Language_Milestones' class='idea_title'><h2>Speech and Language Milestones</h2></div><div class='idea_content'><p>Parents are often fascinated by their child’s development so it is no surprise that one of the most popular pages on Mumsnet is the <a href="http://www.mumsnet.com/devcal">Child Development Calendar</a>. For many parents the first 5 years are particularly exciting with changes happening on an almost daily basis. However for some parents checking their child’s progress can be an anxious time particularly if they have concerns that he/she is not reaching those important milestones as expected.</p>
    <p>When it comes to speech and language development I think one of the best websites that parents can look at to find out what their children should be doing and when is <a href="http://www.talkingpoint.org/">www.talkingpoint.org</a>. As well as providing a guide to how children develop speech and language from pre-birth to 17 years it gives useful tips on how to support children’s communication skills at home. There is also a <a href="http://www.talkingpoint.org.uk/Parent/Directory/Progress-Checker.aspx">Progress Checker</a>. Parents answer a series of questions about their child which helps to identify whether it would be a good idea to seek professional advice.</p>
    <p>Several weeks into the Autumn term and nursery staff or teachers may already have identified children who have problems with listening, understanding, talking, or playing with other children. I have received several phone calls this week from parents who are in exactly that situation. This can be an extremely worrying time as parents are often unaware that their child may be experiencing some difficulties. The <a href="http://www.talkingpoint.org.uk/Parent/Directory/Progress-Checker.aspx">Progress Checker</a> can be a really useful tool for parents to use to help them understand more about their child’s speech and language development. However it is important to remember that these are guidelines only and are not a substitute for talking to a specialist.</p>
    <p>If you have any concerns about your child you are welcome to <a href="mailto:alison@ag-therapy.com]">email</a> or call me for a chat.</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Speech_with_Milo_-_verbs_(iPad_App)' class='idea_title'><h2>Speech with Milo - verbs (iPad App)</h2></div><div class='idea_content'><p>I discovered this <a href="http://itunes.apple.com/us/app/speech-with-milo-verbs/id405441288?mt=8#ls=1">Speech with Milo: verbs</a> iPad app (£1.99) that I’ve started to use in therapy but can also be used by parents. Specific instructions are provided for parents on how to use the app to support their child’s language development. The app is aimed at children age 2-10 and focuses on building vocabulary and developing the use of grammar.  It features a cute looking mouse called Milo who carries out various actions at home, at the beach, and on a stage.  You are able to choose which action words you want which is a great feature as you can tailor it to your own child’s particular needs.  Don’t forget that your child’s age also needs to be taken into account.  For example ‘melt’ or ‘pack’ would not be appropriate for many 2 year olds who are just beginning to put simple phrases together.</p>
    <p>Whilst the app is a really useful tool it is important to remember that this is just one way of helping a child’s language development and should be used alongside other activities such as talking about books, songs and rhymes, role play and everyday conversations.  Please see some of my earlier posts for suggestions.</p>
    <p>There are <a href="http://www.speechwithmilo.com/apps/">several other apps by Speech with Milo</a>.</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Summer_Holiday_Speech_and_Language_Activities' class='idea_title'><h2>Summer Holiday Speech and Language Activities</h2></div><div class='idea_content'><p>Lots of parents of pre-school children ask me what they can do at home over the long Summer holiday to help their child’s language development so here are a few simple ideas.</p>
    <p>Day trips and holidays provide a great opportunity to work on vocabulary development.  Talk about the event in advance.  Make a simple calendar to help your child understand when you’re going and find books or puzzles on that theme.  For example, if you’re going to the zoo you could read a book such as ‘<a href="http://dearzooandfriends.com/">Dear Zoo</a>’ by Rod Campbell.  You could colour zoo animal pictures (there are loads of colouring pages available to download on the Internet), make up stories about going to the zoo with toys or puppets, and look at animal based activities on the <a href="http://www.bbc.co.uk/cbeebies/">CBeebies</a> website.  At the moment they have a very funny song called ‘At the zoo’ which has loads of repetitive language and actions to help your child learn new words.</p>
    <p>Most gift shops sell postcards so you could send one to a friend/relative.  Talk about the picture together and make up a simple message.  After the event you could play ‘I went to the zoo and I saw… (a variation of the memory game ‘I went to market and I bought..’) or you could play guessing games.  Give your child some clues and see if he/she can guess what it is e.g. ‘it’s furry, has a mane and roars’ and then reverse roles so that your child has to describe something and you guess what it is.  Play ‘I spy ..’ but make sure you say the sound and not the letter.  For children at this age it will probably be necessary to give an extra clue e.g. ‘It starts with s and doesn’t have any legs’.</p>
    <p>Another way to help your child is to include him/her in everyday activities such as washing, laying the table, going to the supermarket or putting away the shopping.  The activities will take a little longer than usual if your child is ‘helping’ so allow time for this!</p>
    <p>You can use the activities to help with:</p>
    <ul>
    <li>Following instructions e.g. ‘Find one of Dad’s shirts and put it in the basket’, ‘Put the apples on the table’</li>
    <li>Concept and vocabulary development e.g. big/little (clothes, boxes, cutlery, plates), clean/dirty (clothes, table, floor), long/short (trousers, sleeves, socks), top/bottom (shelf), heavy/light (shopping), soft/hard (food)</li>
    <li>Expressive language
    <ul>
    <li>Give choices e.g. ‘Do you want a big box or a little box?’, ‘Shall we have Weetabix or Cheerios?’</li>
    <li>Start sentences for your child to finish e.g. ‘This is Mummy’s jumper and this is …’ (hold up another item of clothing)</li>
    </ul></li>
    </ul>
    <p>Don’t forget to have fun!  Do something silly or make deliberate mistakes (such as not giving your child a spoon when it is time for pudding) to help encourage spontaneous communication.</p></div><a class='backtocontents' href='#top'>Back to list</a></div><div class='idea_all'><div id='Summer_Holiday_Speech_and_Language_Activities_for_Older_Children' class='idea_title'><h2>Summer Holiday Speech and Language Activities for Older Children</h2></div><div class='idea_content'><p>Here are some ideas for helping older children with their language development during the long Summer holiday.  Some of these could be used on long car journeys to help keep your child occupied.  Encourage siblings to join in too.</p>
    <p><strong>Deliver a verbal message (sensible or silly!)</strong></p>
    <p>For example:</p>
    <ul>
    <li>Ask dad what time we are going to the cinema</li>
    <li>Tell mum that she can have worm or slug sandwiches for lunch</li>
    </ul>
    <p><strong>Pretend you have forgotten how to do an everyday task</strong></p>
    <p>Pretend you have forgotten how to do an everyday task (or you are an alien who has never done something before) e.g. making a peanut butter sandwich.  Your child has to tell you what to do. Do <strong><em>exactly</em></strong> what your child says and encourage him/her to recognise when they have missed something out/given information in the wrong order/used the wrong word.</p>
    <p><strong>Feely bag</strong></p>
    <p>Put several familiar objects in a bag.  Your child has to describe what the object feels like (e.g. soft, hard, furry, cold, round) and then guess what it is.</p>
    <p><strong>Take turns to mime a word for others to guess</strong></p>
    <ul>
    <li>Actions              e.g. cleaning teeth</li>
    <li>Objects             e.g. football</li>
    <li>Feelings             e.g. scared</li>
    <li>Hobbies             e.g. reading</li>
    <li>Occupations       e.g. chef</li>
    </ul>
    <p><strong>I Spy</strong></p>
    <p>Play variations on I spy by imagining that you are somewhere else.  It is best to give the letter sound not the letter name at this stage.  For example:</p>
    <ul>
    <li>Imagine we’re in your bedroom. I spy ..</li>
    <li>Imagine we’re in the garden.  I spy..</li>
    <li>Imagine we’re on a plane.  I spy..</li>
    </ul>
    <p><strong>Looking at Books</strong></p>
    <p>Look at pictures or read your child a short story and ask different types of questions.  Include ones which require your child to make inferences or predictions from what they have read or heard.  For example:</p>
    <ul>
    <li>What did the boy drink?</li>
    <li>Where did the man put his keys?</li>
    <li>Who was hiding in the wardrobe?</li>
    <li>Why did the girl get angry?</li>
    <li>How do you know the boy is sad?</li>
    <li>When did the family go camping?</li>
    <li>What will the boy do next?</li>
    </ul>
    <p><strong>Naming Game</strong></p>
    <p>Name items in different groups e.g. buildings, jobs, weather, feelings, places, hobbies, food etc.  You can do this in lots of ways:</p>
    <ul>
    <li>Roll a dice and name that number of items</li>
    <li>Take turns to name items as quickly as you can</li>
    <li>See how many you can name in 1/2/3 minutes</li>
    <li>Make a scrapbook with a page for different categories and draw/write items in that category</li>
    </ul>
    <p><strong>Going and Seeing Game</strong></p>
    <p>Variations on ‘I went to the market and I bought..’.  For example:</p>
    <ul>
    <li>I went to the park and I saw…</li>
    <li>I went to the swimming pool and I saw…</li>
    <li>I went on holiday and in my suitcase I put…</li>
    </ul>
    <p>I hope that you find these useful.</p></div><a class='backtocontents' href='#top'>Back to list</a></div>

      </div>
    )
  }
}
