import React, { Component } from 'react'
export default class Terms extends Component {


  render() {
    return (
      <div class="main_copy fltlft">
      <h1>Terms and Conditions</h1>


    <p>
    Download a PDF copy of my terms and conditions by clicking this link:
    </p>
    <p>
      <a  target="_blank" rel="noreferrer" href="Terms and ConditionsSept21.pdf">Terms and Conditions.pdf</a>
    </p>
      </div>
    )
  }
}
