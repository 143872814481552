import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import alisonpic from './img/alison.jpg'
import MDFile from './content/about.md'

export default class About extends Component {
  constructor(props) {
    super(props)

    this.state = { markdown: null }
  }
  componentWillMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(MDFile).then(res => res.text()).then(text => this.setState({ markdown: text }));
  }


  render() {
    const { markdown } = this.state;

    return (
      <div class="main_copy fltlft">
      <div class="main_photo"><img src={alisonpic} width="150" height="150" alt="Alison Greig"/></div>
        <ReactMarkdown remarkPlugins={[gfm]} children={markdown} />
      </div>
    )
  }
}
