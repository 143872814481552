import React, { Component } from 'react'

import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import kidsimg from './img/line-of-kids.png'

import MDFile from './content/home.md'

export default class Home extends Component {
  constructor(props) {
    super(props)

    this.state = { markdown: null }
  }

  componentWillMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(MDFile).then(res => res.text()).then(text => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;
    return (
      <div class="main_copy fltlft">
        <ReactMarkdown remarkPlugins={[gfm]} children={markdown} />

        <img class="img_banner" width="600" height="133" src={kidsimg} alt="Line of cartoon children"/>

        </div>



    )
  }
}
