import React, { Component } from 'react'
import kidwaving from './img/kid-waving2.png'


export default class Testimonials extends Component {
  render() {
    return (
      <div class="main_copy fltlft">

        <h1>Testimonials</h1>

        <div class="main_copy">

          <div class="testimonial left">
          <div class="quote">
             It's a wonderful thing to be able to make something constructive into something
             fun and I have no doubt it was Alison's gentle nature and kindness that made it such
             a pleasant and memorable experience for them.
          </div>
          <div class="quote_source">Mrs R, mother of twins</div>
          </div>

          <div class="testimonial right">
          <div class="quote">
             We brought our son to see Alison because his speech was considerably delayed and
             he was easily distracted due to poor understanding. Having had him assessed we were
             told that he was approximately one year behind the average. After four months of
             weekly therapy and doing the games set for us at home, our son has caught up to
             where he should be for his age. He is doing incredibly well and loves coming to
             see Alison, even asking for her on his days off! We are hugely grateful and would
             recommend Alison wholeheartedly'.
          </div>
          <div class="quote_source">Mrs B, mother of 3 year old</div>
          </div>

          <div class="testimonial left">
          <div class="quote">
             My son has been having direct speech and language therapy with Alison Greig for
             approximately two years.  He has made excellent progress and has enjoyed working
             with Alison. She is very approachable, friendly and highly experienced in her field.
             We would highly recommend her.
          </div>
          <div class="quote_source">Mrs M, mother of 10 year old</div>
          </div>

          <img class="kidwavingleft" width="99" height="131" src={kidwaving} alt="Picture of cartoon child waving"/>


          </div>


      </div>
    )
  }
}
