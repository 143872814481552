import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";

import './tabs.css'

export default class Tabs extends Component {
  render() {
    return (
      <div>
      <div class="fltrt menu">
        <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/" exact>Home</NavLink>
        <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/about">About Me</NavLink>
        <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/who">Who Can I Help?</NavLink>
        <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/testimonial">Testimonals</NavLink>
        {/*    <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/fees">Fee Structure</NavLink>*/}
        <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/ideas">Ideas</NavLink>
      </div>
{/*

  <div class="fltrt menu">
    <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/" exact>Home</NavLink>
    <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/about">About Me</NavLink>
    <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/who">Who Can I Help?</NavLink>
    <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/testimonial">Testimonals</NavLink>
    <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/fees">Fee Structure</NavLink>
    <NavLink className="fltrt menu_item" activeClassName="menu_selected" to="/ideas">Ideas</NavLink>
  </div>
*/}

      </div>
    );
  }
}
