import React, { Component } from 'react'
export default class Privacy extends Component {


  render() {
    return (
      <div class="main_copy fltlft">



      <h1>Privacy Policy</h1>
      <p>
        Download a PDF copy of my GDPR Privacy Notice by clicking this link:
      </p>
      <p>
        <a  target="_blank" rel="noreferrer" href="PrivacyNoticeMay2018.pdf">Privacy Notice.pdf</a>
      </p>


      </div>
    )
  }
}
