import React, { Component } from 'react';


import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";



import './saltyal6.css';
import Footer from './components/footer'
import Header from './components/header'
import Home from './components/home'
import About from './components/about'
import Who from './components/who'
import Testimonials from './components/testimonials'
import Fees from './components/fees'
import Ideas from './components/ideas'
import Terms from './components/terms'
import Privacy from './components/privacy'

import CONSTANTS from './components/content/constants'

import imgflower from './img/logo-flower.png';
import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-30706641-1');
ReactGA.pageview(window.location.pathname + window.location.search);
const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class App extends Component {
  render() {
    return (
      <div id="top">
      <Router history={history}>

        <div class="sky_bg"> </div>
        <div class="container">
    			<Header></Header>



            <div class="content">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/who">
                <Who />
              </Route>
              <Route path="/testimonial">
                <Testimonials />
              </Route>
{/*             <Route path="/fees">
                <Fees />
              </Route> */}  
              <Route path="/ideas">
                <Ideas />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>

            </div>

          <img className="left_flower" src={imgflower} alt="flower" />
          <img className="right_flower" src={imgflower} alt="flower" />

          <div class="site_message">{CONSTANTS.sitemessage}</div>
          <Footer></Footer>

        </div>
        </Router>
      </div>


    );
  }
}





export default App;
