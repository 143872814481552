import React, { Component } from 'react';
import logo from './img/Alison-Logo.png';

import Tabs from './tabs';
import './header.css'

import CONSTANTS from './content/constants'




export default class Header extends Component {
  render() {
    return (
      <div class="header">
        <div class="logo">
          <img class="logo_img" src={logo} alt="Surrey Based Speech and Language Therapy from Alison Greig" name="Surrey Based Speech and Language Therapy from Alison Greig" />
        </div>
        <div class="contact"><h1 class="tagline">Surrey Based Speech and Language Therapy</h1>{CONSTANTS.phone} <div class="headerbreak">•</div> <a href={"mailto:"+ CONSTANTS.email}>{CONSTANTS.email}</a></div>
        <Tabs />
      </div>
    );
  }
}
