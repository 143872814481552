import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Link } from "react-router-dom";

import MDFile from './content/fees.md'

export default class Who extends Component {
  constructor(props) {
    super(props)

    this.state = { markdown: null }
  }
  componentWillMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(MDFile).then(res => res.text()).then(text => this.setState({ markdown: text }));
  }


  render() {
    const { markdown } = this.state;

    return (
      <div class="main_copy fltlft">
        <ReactMarkdown remarkPlugins={[gfm]} children={markdown} />
        <br/>
        <p>Please see my <Link to='terms'>terms and conditions</Link> for full details.</p>
      </div>
    )
  }
}
